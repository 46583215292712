<template>
  <div class="waybill-body">
    <el-tabs type="border-card" @tab-click="selectTab">
      <el-tab-pane :label="'全部(' + count.total + ')'">
        <all-number-table ref="all" @getTotal="getTotal"></all-number-table>
      </el-tab-pane>
      <el-tab-pane :label="'未发布(' + count.noPublishCount + ')'">
          <unpublished-table ref="unpublished" @getTotal="getTotal"></unpublished-table>
      </el-tab-pane>
      <el-tab-pane :label="'已发布(' + count.publishCount + ')'">
        <launched-table ref="launched" @getTotal="getTotal"></launched-table>
      </el-tab-pane>
      <el-tab-pane :label="'进行中(' + count.progressCount + ')'">
          <progressing-table ref="progressing" @getTotal="getTotal"></progressing-table>
      </el-tab-pane>
      <el-tab-pane :label="'已完成(' + count.completedCount + ')'">
        <completed-table ref="completed" @getTotal="getTotal"></completed-table>
      </el-tab-pane>
    </el-tabs>
    <!-- 弹窗 -->
  </div>
</template>

<script>
import allNumberTable from './trainNumTableList/allNumber-table.vue'
import launchedTable from './trainNumTableList/launched-table.vue'
import unpublishedTable from './trainNumTableList/unpublished-table.vue'
import progressingTable from './trainNumTableList/progressing-table.vue'
import completedTable from './trainNumTableList/completed-table.vue'
// import detail from './company-detail'
export default {
  data () {
    return {
      tabActive: '1',
      count: {
        noPublishCount: 0,
        publishCount: 0,
        progressCount: 0,
        completedCount: 0,
        total: 0
      }
    }
  },
  components: {
    allNumberTable,
    launchedTable,
    unpublishedTable,
    progressingTable,
    completedTable
  },
  activated () {
    this.getTotal()
    this.selectTab({
      label: '全部'
    })
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    // 选择标签
    selectTab (flag) {
      if (flag.label.indexOf('全部') > -1) {
        this.$refs.all.getDataList()
      } else if (flag.label.indexOf('已发布') > -1) {
        this.$refs.launched.getDataList()
      } else if (flag.label.indexOf('未发布') > -1) {
        this.$refs.unpublished.getDataList()
      } else if (flag.label.indexOf('进行中') > -1) {
        this.$refs.progressing.getDataList()
      } else if (flag.label.indexOf('已完成') > -1) {
        this.$refs.completed.getDataList()
      }
    },
    getTotal () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/vehiclePlan/count/listTitle'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.count.noPublishCount = data.noPublishCount
        this.count.publishCount = data.publishCount
        this.count.total = data.total
        this.count.progressCount = data.progressCount
        this.count.completedCount = data.completedCount
        this.dataListLoading = false
      })
    }
  }
}
</script>
<style lang="less">
#pane-operPlatform-trainNumber {
  .el-tabs--border-card>.el-tabs__content {
    padding: 5px;
  }
}
</style>
